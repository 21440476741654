<template>
  <div class="cart">
    <div class="container">
      <div class="sort-hd__hd pt-3 pb-2">
        <router-link to="/">首页</router-link>
        <div class="brand-tag">
          <el-icon>
            <ArrowRight />
          </el-icon>
          购物车
        </div>
      </div>
    </div>
    <div class="cart-hd container pr-10 mb-10">
      <div class="cart-hd-inner pr-12">
        <el-table
          class="cart-table"
          ref="multipleTableRef"
          :data="tableData['items']"
          :header-row-style="{ 'background-color': '#fafafa' }"
          :header-cell-style="{
            'background-color': '#fafafa',
            padding: '10px 0'
          }"
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column width="100">
            <template #header>
              <el-checkbox
                v-model="tableData['is_selected_all']"
                :true-label="1"
                :false-label="0"
                :indeterminate="isIndeterminate"
                @change="onSelected"
                :disabled="
                  !tableData ||
                  !tableData['items'] ||
                  tableData['items']['length'] == 0
                "
                class="cart-table-checkbox"
                >全选</el-checkbox
              >
            </template>
            <template #default="scope">
              <el-checkbox
                v-model="scope.row['selected']"
                :true-label="1"
                :false-label="0"
                @change="onSelected(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in header"
            :key="idx"
            :label="item.title"
            :prop="item.idx"
            :width="item.width"
            :align="item.align || 'center'"
            :fixed="item.fixed"
          >
            <template v-if="idx == 'goods'" #default="scope">
              <div class="cart-info">
                <div class="cart-info-banner">
                  <img :src="scope.row.goods.img" alt="" />
                </div>
                <div class="cart-info-name">
                  <a :href="'/#/goodsDetail?id=' + scope.row.goods.id" target="_blank" rel="noreferrer">{{ scope.row.goods_title }}</a>
                </div>
              </div>
            </template>
            <template v-if="idx == 'number'" #default="scope">
              <el-input-number
                v-model="scope.row['goods_number']"
                :min="1"
                @change="handleChange($event, scope.row)"
              />
            </template>
            <template v-if="idx == 'actions'" #default="scope">
              <el-button type="danger" @click="onDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="tableData.selected_number" class="cart-item container pr-10">
      <div class="cart-item-inner pr-12">
        <div class="cart-control">
          <div class="cart-control-info">
            已选{{ tableData.selected_number }}件
          </div>
          <div class="cart-control-after">
            <el-button
              class="cart-control-btn"
              type="primary"
              @click="dialogVisible = true"
              >去报价</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      custom-class="cart-dialog"
      append-to-body
      width="40%"
    >
      <template #header>
        <div class="my-header">
          <h4 class="my-header-title">商品报价</h4>
          <div class="my-header-text">
            感谢您的预约，我们的商务人员会在24小时内联系您，或致电400-8875-800
          </div>
        </div>
      </template>
      <Form v-if="dialogVisible" @submitForm="submitForm" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'
import Form from './components/form.vue'
import { useStore } from 'vuex'
import {
  cartSelected,
  cartCheckout,
  cartDelete,
  cartUpdate
} from '@/api/cart.js'
import { ElMessage, ElMessageBox } from 'element-plus'
const store = useStore()
const header = {
  goods: {
    title: '商品信息'
  },
  number: {
    title: '数量',
    width: 180
  },
  actions: {
    title: '操作',
    width: 100,
    fixed: 'right'
  }
}
const multipleTableRef = ref(null)
const isIndeterminate = ref(false)
const tableData = computed(() => {
  const objects = store.getters?.cartData
  const { selected_number, goods_number } = objects
  if (goods_number && selected_number && goods_number > selected_number) {
    isIndeterminate.value = true
  } else {
    isIndeterminate.value = false
  }
  return objects
})
const loading = ref(false)
const timer = ref(null)
const handleChange = (e, item) => {
  clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    const { id } = item || {}
    cartUpdate({ cart_id: id, goods_number: e })
      .then((res) => {
        const { error } = res
        if (error == 0) {
          store.dispatch('app/getCartList')
        }
      })
      .catch(() => {
        store.dispatch('app/getCartList')
      })
  }, 300)
}
const dialogVisible = ref(false)
const handleClose = () => {}
onMounted(() => {})
onUnmounted(() => {
  clearTimeout(timer.value)
})
const submitForm = (e) => {
  const temp = _.cloneDeep(e)
  temp['shipping_id'] = 0
  cartCheckout(temp).then((res) => {
    const { error } = res
    if (error == 0) {
      store.dispatch('app/getCartList').then(() => {
        setTimeout(() => {
          dialogVisible.value = false
        }, 0 * 1000)
      })
    }
  })
}
const onDelete = (e) => {
  ElMessageBox.confirm('是否确认删除此商品', '提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      const { id } = e || {}
      cartDelete({ cart_ids: id }).then((res) => {
        const { error } = res
        if (error == 0) {
          loading.value = true
          store
            .dispatch('app/getCartList')
            .then(() => {
              setTimeout(() => {
                loading.value = false
              }, 0 * 1000)
            })
            .catch(() => {
              setTimeout(() => {
                loading.value = false
              }, 1 * 1000)
            })
        }
      })
    })
    .catch(() => {})
}
const onSelected = (params) => {
  loading.value = true
  setTimeout(() => {
    loading.value = false
  }, 20 * 1000)
  let temp = {}
  if (params === 1 || params === 0) {
    temp = {
      selected: params,
      is_all_cart: 1
    }
  } else {
    const { selected, id } = params || {}
    temp = {
      selected,
      cart_ids: id
    }
  }
  cartSelected(temp).then((res) => {
    const { error } = res
    if (error == 0) {
      store
        .dispatch('app/getCartList')
        .then(() => {
          setTimeout(() => {
            loading.value = false
          }, 0 * 1000)
        })
        .catch(() => {
          setTimeout(() => {
            loading.value = false
          }, 1 * 1000)
        })
    }
  })
}
store.dispatch('app/getCartList')
</script>

<style lang="scss" scoped>
.sort {
  &-hd {
    width: 200px;
    &__hd {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 16px;
      font-weight: 300;
      color: #191818;
      margin-right: -40px;
      a {
        color: #191818;
      }
    }
  }
}

.brand {
  &-tag {
    display: flex;
    align-items: center;
    :deep(.el-tag--large) {
      font-size: 18px;
    }
  }
}
.cart {
  background-color: #f5f5f5;
  min-height: calc(100vh - 68px);
  border-bottom: 1px solid;
  &-info {
    display: flex;
    align-items: center;
    &-banner {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-name {
      flex: 1;
      width: 50%;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      padding-left: 20px;
    }
  }
  &-control {
    background-color: #fff;
    display: flex;
    height: 88px;
    align-items: center;
    margin-bottom: 60px;
    &-info {
      font-size: 18px;
      flex: 1;
      width: 80%;
      padding-left: 20px;
    }
    &-after {
      width: 160px;
      height: 88px;
    }
    &-btn {
      width: 100%;
      height: 100%;
      font-size: 24px;
      &:hover {
        background-color: #a7a3a0;
        border-color: #a7a3a0;
      }
    }
  }
  &-table {
    font-size: 16px;
    :deep(.el-checkbox) {
      margin-left: 15px;
    }
    :deep(.el-checkbox__inner) {
      width: 20px;
      height: 20px;
      font-size: 20px;
      &::after {
        border-width: 2px;
        width: 4px;
        height: 10px;
        left: 6px;
        top: 2px;
      }
    }
    :deep(.el-checkbox__input.is-indeterminate) {
      .el-checkbox__inner::before {
        height: 3px;
        top: 7px;
      }
    }
  }
}
.my {
  &-header {
    &-title {
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 37px;
    }
    &-text {
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
      margin-bottom: 20px;
      width: 485px;
      margin: 0 auto;
    }
  }
}

html a:link,
html a:visited {
  color: #666;
}

html a:hover {
  color: #e2231a;
  cursor: pointer;
}
</style>
