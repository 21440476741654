<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    status-icon
    size="large"
  >
    <el-form-item
      v-for="(item, idx) in datas"
      :key="idx"
      :label="item.title"
      :prop="idx"
      :rules="[
        {
          required: item.required !== false,
          message: '请输入',
          trigger: 'blur'
        },
        item.type == 'email'
          ? {
              type: 'email',
              message: '请输入正确邮箱',
              trigger: ['blur', 'change']
            }
          : ''
      ]"
    >
    <vue-tel-input v-if="item.type == 'mobile'" v-model="ruleForm.mobile" mode="national"
        :default-country="CN"
        :selectedCountry="{ iso2: 'CN' }"
        auto-default-country
        :preferred-countries="['CN']"
        placeholder="请输入手机号"
        @input="inputMobile"
        @country-changed="handleCountryChange"
        enabledCountryCode="true"
      />
      <el-input v-else
        :type="item.type"
        :autosize="item.autosize"
        v-model="ruleForm[idx]"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        class="form-btn"
        type="primary"
        @click="submitForm(ruleFormRef)"
        :disabled="disabled"
        v-loading="disabled"
        >确认</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
// import { VueTelInput } from 'vue-tel-input'
// import '../../../assets/css/vue-tel-input.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ElMessage} from 'element-plus'
const datas = {
  consignee: {
    title: '姓名'
  },
  mobile: {
    title: '手机号',
    type: 'mobile'
  }, // 手机号
  company_title: {
    title: '公司名称'
  }, // 公司名称
  email: {
    title: '邮箱',
    type: 'email'
  }, // 邮箱
  note: {
    title: '您的需求',
    type: 'textarea',
    required: false,
    autosize: { minRows: 5 }
  } // 邮箱
}
const ruleForm = ref({
  consignee: '',
  mobile: '', // 手机号
  company_title: '', // 公司名称
  email: '',
  note: '',
  dial_code: '' // 手机区号
  // country_code: ''//国家
})
const ruleFormRef = ref(null)
const emit = defineEmits()
const disabled = ref(false)
const submitForm = (e) => {
  ruleForm.value.mobile = ruleForm.value.mobile.replace(/\s/g, '')
  const phoneNumber = parsePhoneNumberFromString(ruleForm.value.mobile, ruleForm.value.country_code)
  if (phoneNumber && phoneNumber.isValid()) {
    // console.log('valid')
  } else {
    // console.log('invalid')
    ElMessage({
      duration: 2000,
      message: '请输入正确的手机号',
      type: 'error'
    })
    return
  }
  e.validate((confirm) => {
    if (confirm) {
      disabled.value = true
      setTimeout(() => {
        disabled.value = false
      }, 2000)
      emit('submitForm', ruleForm.value)
    }
  })
}

const inputMobile = (e) => {
  if (e.target) {
    ruleForm.value.mobile = e.target.value.replace(/[()\s-]/g, '')
  }
}

const handleCountryChange = (value) => {
  // console.log('handleCountryChange', value)
  ruleForm.value.dial_code = value.dialCode
  ruleForm.value.country_code = value.iso2
}

</script>

<style lang="scss" scoped>
.form {
  &-btn {
    display: block;
    width: 175px;
    height: auto;
    font-size: 25px;
    font-weight: 400;
    color: #ffffff;
    margin: 0 auto;
    border-radius: 13px;
    position: relative;
    right: 40px;
  }
}

.vue-tel-input {
  width: 100%
}
</style>
